<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M10 15.74H2a1.75 1.75 0 0 1-1.75-1.748V1.999C.25 1.033 1.034.25 2 .25h8c.967 0 1.75.783 1.75 1.75v11.992A1.75 1.75 0 0 1 10 15.74zm0-1.5a.25.25 0 0 0 .25-.248V1.999A.25.25 0 0 0 10 1.75H2a.25.25 0 0 0-.25.25v11.992c0 .137.112.249.25.249h8z"
    />
    <path d="M5 12.743h2a.75.75 0 1 0 0-1.5H5a.75.75 0 1 0 0 1.5z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
